<template>
  <div class="department-tree">
    <div class="department-tree-title">
      部门架构
      <div class="department-tree-opr">
        <el-button type="text" @click="horizontal=!horizontal">旋转展示</el-button>
        <el-button type="primary" size="small" v-if="$btnCheck('org_architecture_department_create')" @click="showDept=true">新增部门</el-button>
      </div>
    </div>
    <template v-for="(group, idx) in groups">
      <org-chart
        :key="idx"
        :class="horizontal ? 'department-tree-chart-horizontal':'department-tree-chart-vertical'"
        class="department-tree-chart"
        :data="group"
        :horizontal="horizontal"
        :collapsable="collapsable"
        :label-class-name="labelClass"
        :render-content="renderContent"
        @on-expand="onExpand"
        @on-node-click="onNodeClick">
      </org-chart>
    </template>

    <el-dialog
      width="520px"
      v-if="$btnCheck('org_architecture_department_create')"
      :visible.sync="showDept"
      title="新增部门"
    >
      <el-form label-width="80px">
        <el-form-item
          label="部门名称">
          <el-input v-model="department.name"/>
        </el-form-item>

        <el-form-item
          label="父级部门"
        >
          <el-select v-model="department.parentUuid">
            <el-option :value="department.uuid" v-for="(department, key) in departments" :key="key" :label="department.name">
              {{department.name}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-button size="small" type="primary" @click="handleDeptCreate">确认</el-button>
          <el-button size="small" @click="showDept = false">取消</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>

    <slider :visible.sync="memberVisible"
            :width="'60%'">
      <div class="department-info">
        <div class="department-title">
          <div class="name">
            <span class="name-value">{{department.label}}</span>
            <el-popover v-model="showEdit" title="部门重命名" trigger="manual" v-if="$btnCheck('org_architecture_department_update')">
              <div>
                <el-form label-width="80px">
                  <el-form-item
                    style="margin-bottom: 4px"
                    label="部门名称">
                    <el-input v-model="department.name"/>
                  </el-form-item>

                  <el-form-item style="margin-bottom: 0">
                    <el-button type="primary" plain size="small" @click="showEdit=false"> 取消</el-button>
                    <el-button type="primary" size="small" style="margin-left: 16px"
                              @click="handleDeptUpdate">提交更新</el-button>

                  </el-form-item>
                </el-form>
              </div>
              <span slot="reference" class="edit" @click="showEdit=true"> <i class="el-icon-edit"></i> 编辑部门</span>
            </el-popover>

          </div>

          <div class="delete" v-if="$btnCheck('org_architecture_department_delete')">
            <el-popover v-model="showDelete" title="删除部门" trigger="manual">
              <div >
                  <div style="margin: 8px 0">部门删除成功后不可恢复,是否删除</div>
                  <div>
                    <el-button type="danger" style="margin-right: 8px" size="small" @click="handleDelete">删除</el-button>
                    <el-button @click="showDelete=false" size="small">取消</el-button>
                  </div>
              </div>
              <span slot="reference" class="delete" @click="showDelete = true"><i class="el-icon-delete"></i> 删除部门</span>
            </el-popover>
          </div>
        </div>
        <div class="employee-list">
          <el-radio-group default-value="current" size="small" v-model="position" class="employee-filter">
            <el-radio-button label="current" @click.native="handleSwUsr('current')">当前部门成员</el-radio-button>
            <el-radio-button label="other" @click.native="handleSwUsr('other')">其他部门成员</el-radio-button>
          </el-radio-group>

          <employee  ref="employee"></employee>

        </div>
      </div>
    </slider>
  </div>
</template>

<script>
  import OrgChart from '@/components/OrgChart'
  import Slider from '@/components/Slider'
  import {checkObj} from "@/utils/check"

  import Employee from './components/user'

  export default {
    name: '',
    components: {
      OrgChart,
      Slider,
      Employee
    },
    mounted: function () {
      this.loadDepartment()
    },
    data: function () {
      return {
        horizontal: true,
        collapsable: true,
        groups: [],
        showDept: false,
        showEdit: false,
        showDelete: false,
        memberVisible: false,
        position: 'current',
        currentUuid: 'current',
        departments: [],
        department: {
          name: '',
          parentUuid: '',
        },
      }
    },
    methods: {
      renderContent(h, data) {
        return data.label;
      },
      onExpand(data) {
        if ("expand" in data) {
          data.expand = !data.expand;
          if (!data.expand && data.children) {
            this.$set(data, "expand", true);
          }
        } else {
          this.$set(data, "expand", true);
        }
      },
      onNodeClick(e, data) {
        if (!this.$btnCheck('org_architecture_department_update')) {
          return;
        }
        this.department.uuid = data.uuid
        this.department.label = data.label
        this.department.name = data.label
        // this.$refs.employee.changeDepartment(this.currentUuid, true)
        this.position = 'current'
        this.$refs.employee.loadProve(this.department.uuid)
        this.memberVisible = true

      },
      handleSwUsr(position) {
        this.position = position
        this.$refs.employee.loadProve(this.department.uuid, this.position === 'current')
      },
      labelClass(data) {
        switch (data.level) {
          case 0:
            this.$set(data, "expand", true);
            return 'first';
          case 1:
            this.$set(data, "expand", true);
            return 'second';
          case 2:
            this.$set(data, "expand", true);
            return 'three';
          default:
            return 'other';
        }
      },

      selectCommand() {

      },
      handleDelete() {
        this.$api('org.department.delete',{
          uuid: this.department.uuid,
        }).then(() => {
          this.showDelete = false
          this.showDept = false
          this.memberVisible = false
          this.$message.success('删除成功')
          this.loadDepartment()
        })
      },
      handleDeptUpdate() {
        if (!this.department.name){
          this.$message.warning('请输入部门名称')
          return
        }
        this.$api('org.department.update',{},{
          uuid: this.department.uuid,
          name: this.department.name,
        }).then(() => {
          this.showEdit = false
          this.department.label =  this.department.name
          this.$message.success('更新成功')
          this.department.name = ''
          this.loadDepartment()
        })
      },
      loadDepartment() {

        this.$api('org.department.tree').then(data => {
          this.groups = data || []
        })

        this.$api('org.department.list').then(data => {
          this.departments = data || []
        })
      },
      handleDeptCreate() {
        let result = checkObj(this.department, [
          {value: 'name', message: '请输入部门名称'},
          {value: 'parentUuid', message: '请选取所在部门'},
        ])

        if (!result.status) {
          this.$message.warn(result.message)
          return
        }
        this.$api('org.department.create',{},this.department).then(() => {
          this.loadDepartment()
          this.showDept = false
          this.department.name = ''
          this.$message.success('创建成功')
        })
      },

    },
  }
</script>

<style lang="scss" scoped>
  .department-tree {
   @include container();
    &-title{
      @include page-title();
    }
    &-opr {
     float: right;
    }

    &-chart-horizontal {
       .org-tree {
        margin: auto 0;
        position: absolute;
        top: 35%;
        transform: translate(0, -50%);
      }
    }


    &-chart-vertical{
      margin: auto;
    }
    .edit, .delete {
      cursor: pointer;
    }

    .edit{
      color: $theme-color;
    }

    .delete {
      color :$brand-danger;
    }

    &-chart-vertical {
       .org-tree {
        margin: auto 0;
        position: absolute;
        top: 25%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      }
    }

    &-chart {
      height: 100%;
      width: 100%;
      position: relative;


       .first,  .second,  .three,  .other {
        @include font-medium-s();
        cursor: pointer;
        border-radius: $box-mini-radius;
        /*width: 100px;*/
      }

       .first:hover,  .second:hover,  .three:hover,  .other:hover {
        background-color: $theme-color;
        border: 2px solid $theme-color;
        color: $color-white;
      }

       .first {
        border: 2px solid $theme-color;
      }

       .second {
        border: 2px solid $brand-info;
      }

       .three {
        border: 2px solid $brand-warning;
      }
    }

    .department-info{
      padding: $middle-space;
      .department-title{
        display: flex;
        .name{

          flex: 1;

          .name-value{
            @include font-large-s();
            font-weight: 500;
            margin-right: $small-space;
          }
        }
      }



      .employee-list {
        text-align: center;
        padding: $small-space;

        .employee-filter {
          margin: $large-space 0;
        }
      }
    }



  }
</style>
